import {gatherCommonPaymentData} from "./common";

export const ciCreditKey = {
  async pay({
              additionalUserCost,
              totalPrice,
              setupFee,
              agent,
              licenseType,
              packageName,
              creditKeyCheckoutUrl,
              creditKeySuccessUrl,
              time,
              formattedNonTeamPrice,
              packageId,
            }) {
    let {paymentCreateRequestData, checkoutBeginRequestData} = gatherCommonPaymentData({
      additionalUserCost,
      totalPrice,
      setupFee,
      packageName,
      agent,
      successUrl: creditKeySuccessUrl,
      time,
      formattedNonTeamPrice,
      licenseType,
      packageId,
    })

    return new Promise((resolve, reject) => {
      $.post('/payment-create', paymentCreateRequestData, 'json')
        .done((data) => {
          const paymentCreateResponse = JSON.parse(data);
          const creditKeyRequestData = {...checkoutBeginRequestData, remote_id: paymentCreateResponse.id}

          $.ajax({
              data: JSON.stringify(creditKeyRequestData),
              crossDomain: true,
              processData: false,
              'url': creditKeyCheckoutUrl,
              'method': 'POST',
              'timeout': 0,
              'headers': {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
            })
            .done((checkoutBeginResponse) => {
              const paymentUpdateRequest = {
                id: paymentCreateResponse.id,
                externalId: checkoutBeginResponse.id,
              };

              $.post('/payment-update', paymentUpdateRequest, 'json')
                .done(() => {
                  resolve({
                    successUrl: checkoutBeginResponse.checkout_url
                  })
                })
                .fail(e => {
                  handleFail('PAYMENT_UPDATE_ERROR', e);
                });
            })
            .fail(e => {
              handleFail('CHECKOUT_BEGIN_ERROR', e);
            });
        })
        .fail(e => {
          handleFail('PAYMENT_CREATE_ERROR', e);
        });

      function handleFail(message, e) {
        console.log('FAIL', message, e)
        reject(message);
      }
    })
  }
}
