const ciManagePayments = {
  init(upgradesPrices) {
    const $form = $("#teamUpgradeForm");
    clickedin.general.encodingValidation($form);
    clickedin.general.allowOnlyBusinessEmails($(".users-container"));
    this.bindFormSubmit($form);
    this.loadUser();
  },

  onUserLoaded(packageDurationCode, boughtUpgrades, userEmail, userId) {
    const upgradesPrices = {
      year: 1188,
      semiannual: 594,
      quarter: 297,
      month: 97
    }

    const durationText = getPackageDurationText(packageDurationCode)
    const upgradePrice = upgradesPrices[durationText];

    $('#upgradesSection .custom-checkbox__input').attr('data-price', upgradePrice);

    boughtUpgrades.forEach(item => {
      $(`#upgradesSection [data-upgrade="${item.feature_id}"]`)
        .attr('disabled', true)
        .attr('checked', true);
    });

    $('#email').val(userEmail);
    $('#userId').val(userId);
    $('#recurringPeriod').val(durationText);

    (function bindUpgradesChange() {
      $('#upgradesSection :checkbox').on('change', function() {
        let upgrades = [];

        $('#upgradesSection :checkbox:not([disabled]):checked').each(function() {
          upgrades.push({
            upgrade: $(this).data('upgrade'),
            price: $(this).data('price')
          });
        });

        let price = 0;
        let upgradesTitles = [];

        upgrades.forEach((item) => {
          price += item.price;
          upgradesTitles.push(item.upgrade);
        });

        $('#selectedUpgrades').val(upgradesTitles.join(','));
        $('#todayTotalPrice').text(`$${price.toFixed(2)}*`);
      });
    })();
  },

  loadUser() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    return $.ajax({
      url: `/verify-upgrade-token?token=${token}`,
      type: 'text/javascript',
      method: 'GET'
    }).then((response) => {
      if (response && response.user) {
        userLoadingSuccess()
        ciManagePayments.onUserLoaded(response.user.package_duration, response.user.upgrades, response.user.email, response.user.id);
      } else {
        userLoadingFailed()
      }
    }, userLoadingFailed)

    function userLoadingFailed() {
      $("#error").show().find('h4').text('Error: Invalid token')
      $("#loading").hide();
    }

    function userLoadingSuccess(){
      $("#order-details-finish").show();
      $("#loading").hide();
    }
  },

  bindFormSubmit($form) {
    $form.on('submit', function () {
      if (!$('#terms').is(':checked')) {
        $('p.error').html('Please, accept the terms and conditions.');
        return false;
      }

      let form = $(this);
      $('#finish-upgrade-btn').text('Processing...').addClass('hold');

      let data = clickedin.payment.gatherFormData($form);

      $.ajax({
        type: 'POST',
        url: '/add-upgrades',
        data: 'data=' + encodeURIComponent(JSON.stringify(data)),
        dataType: 'json',
        success: function (response) {
          if (response && response.status) {
            $("#thank-you-message").show();
            $("#teamUpgradeForm").hide();
          } else {
            $('p.error', form).html('Error: ' + response.error);
            $('#finish-upgrade-btn').text('Pay with Stripe').removeClass('hold');
          }
        },
        error: function (xhr, status, error) {
          $('p.error', form).html(xhr.responseText);
          $('#finish-upgrade-btn').text('Pay with Stripe').removeClass('hold');
        }
      });

      return false;
    })
  },
}

const getPackageDurationText = (packageDurationCode) => {
  let duration = '';

  switch (packageDurationCode) {
    case 1:
      duration = 'semiannual';
      break;
    case 2:
      duration = 'year';
      break;
    case 3:
      duration = 'quarter';
      break;
    case 4:
      duration = '2 years';
      break;
    case 0:
    default:
      duration = 'month';
      break;
  }

  return duration;
}


export default ciManagePayments;
