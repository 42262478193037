export const gatherCommonPaymentData = ({
                                   additionalUserCost,
                                   totalPrice,
                                   setupFee,
                                   agent,
                                   licenseType,
                                   packageName,
                                   successUrl,
                                   time,
                                   formattedNonTeamPrice,
                                   packageId,
                                 }) => {

  const formData = $('#checkout').serializeArray();
  let checkoutBeginRequestData;
  const paymentCreateRequestData = {
    licenseType: licenseType,
    provider: 'CREDIT_KEY',
    data: {}
  };

  let paymentData = {
    package: packageName,
    referred: agent,
    setupFee: setupFee && parseFloat(setupFee).toFixed(2) || null,
  };

  $.map(formData, (item) => {
    paymentData[item['name']] = item['value'];
  });

  if (licenseType === 'team') {
    const additionalUsersCount = $('.secondary-user-row').length;
    const additionalUsersCountExtra = additionalUsersCount > 4 ? (additionalUsersCount - 4) : 0;
    const additionalUsersPrice = additionalUsersCountExtra * additionalUserCost;
    const upgradesPrice = parseFloat($('#upgradesSection').data('upgradesPrice') || 0);

    paymentData.additionalUserCost = additionalUserCost.toFixed(2);
    paymentData.additionalUsers = [];
    paymentData.additionalUsersCount = additionalUsersCount;
    paymentData.additionalUsersCountExtra = additionalUsersCountExtra;
    paymentData.additionalUsersPrice = additionalUsersPrice.toFixed(2);
    paymentData.price = (totalPrice + additionalUsersPrice + upgradesPrice).toFixed(2);

    for (let i = 0; i < additionalUsersCount; i++) {
      const elementIndex = i + 2;
      paymentData.additionalUsers.push({
        email: paymentData[`teamUserEmail-${elementIndex}`],
        firstName: paymentData[`teamUserFirstName-${elementIndex}`],
        lastName: paymentData[`teamUserLastName-${elementIndex}`],
      });
    }
  } else {
    paymentData.price = formattedNonTeamPrice.replace(',', '').replace('$', '');
  }

  paymentCreateRequestData.data = {
    ...paymentData,
    sysFirstName: paymentData.firstName,
    sysLastName: paymentData.lastName
  }

  checkoutBeginRequestData = {
    'remote_id': null,
    'cart_items': [
      {
        'merchant_id': time,
        'name': paymentData.package,
        'price': paymentData.price,
        'quantity': 1,
      }
    ],
    'billing_address': {
      'first_name': paymentData.firstName,
      'last_name': paymentData.lastName,
      'company_name': paymentData.company,
      'email': paymentData.email,
      'address1': paymentData.address,
      'city': paymentData.city,
      'state': paymentData.state,
      'zip': paymentData['postal'] || '',
      'phone_number': paymentData.phone
    },
    'shipping_address': {
      'first_name': paymentData.firstName,
      'last_name': paymentData.lastName,
      'company_name': paymentData.company,
      'email': paymentData.email,
      'address1': paymentData.address,
      'city': paymentData.city,
      'state': paymentData.state,
      'zip': paymentData['postal'] || '',
      'phone_number': paymentData.phone
    },
    'charges': {
      'total': paymentData.price,
      'grand_total': paymentData.price,
    },
    'return_url': successUrl,
    'cancel_url': 'https://clickedin.io/pricingplan/' + packageId,
    'order_complete_url': successUrl,
    'mode': 'redirect',
  };

  return {checkoutBeginRequestData, paymentCreateRequestData}

}
