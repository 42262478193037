import './css/swiper-slider.css'
import 'animate.css'
// import './css/style.css'
import './css/variables.scss'
import './css/checkout.scss'
import './css/old.scss'
import './css/new.scss'

import './js/home';
import './js/global';
import './js/clickedin/clickedin'
