const TESTS = {
  'banner': {
    a() {
      $('#homeBanner').addClass('banner--variant-a');
      $('.banner-home-block').removeClass('hidden');
    },
    b() {
      $('#homeBanner').addClass('banner--variant-b')
      $('.banner-home-block').removeClass('hidden');
    }
  }
}

const ciABTesting = {
  run(test, variant) {
    if (!TESTS[test]) {
      console.error('Invalid test name, aborting. Test name: ', test);
      return;
    }

    if (typeof TESTS[test][variant] !== 'function') {
      console.error('Invalid test variant, aborting. Variant name: ', variant);
      return;
    }

    $(() => {
      TESTS[test][variant]();
    });
  }
};

export default ciABTesting;
