import {gatherCommonPaymentData} from "./common";

// test app
// acc
// sb-x8o7e24978644@business.example.com
// client id
// AUoQ52RQvyhQ_n5kw8soQoc_ZdZtFRw6zJv4MaKLeTMnhlyhVP4JlPbF4GV9DJtXyy2L72rlwB3kb8Qj
// secret
// EModAy2knTr2ttfYOSCPb5Gla8RGZiYmiAS34ihvmh2M46IMS5Pd9b-usCYSlygz7jRlJGgYoIBOdn6e

// client id
//AdE2W_BrmxtMypGLCqAryRAm62R41fYwQbwIIBt7iQEMUeInSsz4QuArj6PEusrOBcrmOeQMzZ7iqd-k
// secret
//EJpNNODlUT8LMcpPDR5VtsL5jAcT7KPsWE8GCaZbohGfvwBcqA1KBGaW4nZltlbLXQ31JgGqs_X4powU

/*
* trial days on payment
* paypal response metadata --
* create plans?!?!?!?!
* re-init when entered promocode --
* provide real data
* */

export const ciPaypal = {
  subscribe({formData, metaData, onSuccess}) {
    return new Promise((resolve, reject) => {
      const sendData = {...formData, paymentMetadata: metaData};

      $.ajax({
        type: 'POST',
        url: '/paypal-subscribe',
        data: 'data=' + encodeURIComponent(JSON.stringify(sendData)),
        dataType: 'json',
        success: function (response) {
          if (response && response.status) {
            resolve(response);

            if (typeof onSuccess === 'function') {
              onSuccess();
            }
          } else {
            try{window['grecaptcha'].reset()}catch (e) {}
            reject(response?.error);
          }
        },
        error: function (xhr, status, error) {
          reject(xhr.responseText);
        }
      });
    })
  },

  initPaypal(serverData) {
    if (!document.getElementById("paypalButtons")) return;

    const fundingSources = [
      paypal.FUNDING.PAYPAL,
      paypal.FUNDING.CREDIT,
      paypal.FUNDING.PAYLATER,
      paypal.FUNDING.CARD,
    ];

    let paypalButtonsComponent;

    for (const fundingSource of fundingSources) {
      paypalButtonsComponent = paypal.Buttons({
        fundingSource: fundingSource,

        // optional styling for buttons
        // https://developer.paypal.com/docs/checkout/standard/customize/buttons-style-guide/

        style: {
          shape: "rect",
          height: 48,
          label: "subscribe"
        },

        // set up the recurring transaction
        createSubscription: (data, actions) => {
          // replace with your subscription plan id
          // https://developer.paypal.com/docs/subscriptions/#link-createplan
          return actions.subscription.create({
            plan_id: serverData.paypalPlan,
          });
        },

        // notify the buyer that the subscription is successful
        onApprove: (data, actions) => {
          const formData = clickedin.payment.gatherFormData($('#checkout'));

          clickedin.payment.paypal.subscribe({formData, metaData: data, onSuccess: () => {
              location.href = `/thank-you-sub?em=${encodeURIComponent(formData['email'])}`;
            }})
        },

        // handle unrecoverable errors
        onError: (err) => {
          console.log('here err', {err});
          clickedin.payment.setErrorMessage()
        },
        onClick: async function(data, actions) {
          const licenseType = !!$('#teamUsers').length ? 'team' : 'single';
          clickedin.payment.setErrorMessage('');
          return clickedin.payment.validateCheckoutForm(licenseType).then((errorMessage) => {
            if (errorMessage) {
              clickedin.payment.setErrorMessage(errorMessage);
              setTimeout(() => {
                $('#checkout')[0].reportValidity();
              });
              return actions.reject();
            } else {
              return actions.resolve();
            }
          });
        }
      });

      if (paypalButtonsComponent.isEligible()) {
        paypalButtonsComponent
          .render("#paypalButtons")
          .catch((err) => {

          });
      }
    }

    return () => {
      paypalButtonsComponent.close();
      $('#paypalButtons').html('');
    }
  },
}

