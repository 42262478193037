import $ from 'jquery';

(function (window) {
  function customLazy(){
    $('.js-lazy').each(function() {
      $(this).attr('src', $(this).attr('data-src'))
    })
  }

  function initYoutubeLazy() {
    $('body').on('click', '.js-youtube-preloader', function(e) {
      e.preventDefault();

      const $wrap = $(this).parents('.embedded-video-wrap');
      if ($wrap.hasClass('has-video')) return;

      $wrap.addClass('has-video');
      const videoId = $(this).attr('data-id');
      const videoUrl = $(this).attr('data-video-url') ? $(this).attr('data-video-url') :
        `https://www.youtube.com/embed/${videoId}?feature=oembed&wmode=opaque&loop=0&controls=1&autoplay=1&rel=0`;

      $(this).html(`<iframe class="video-iframe"
            allowfullscreen
            title="Clickedin video"
            src="${videoUrl}">
          </iframe>`)
    })
  }

  function initMobileNavToggle() {
    let $burger = document.querySelectorAll('.burger');
    let $nav = document.querySelectorAll('.nav-menu--main');

    const navActiveClass = 'js--active';
    const burgerActiveClass = 'js--open';

    if (!$burger.length || !$nav.length) return;

    $burger = $burger[0];
    $nav = $nav[0];

    $burger.addEventListener('click', function (e) {
      e.preventDefault();
      toggleNav();
    });

    bindMenuItemsClick();
    bindResize();

    function bindResize() {
      let timeout = 0;

      window.addEventListener('resize', function () {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(function () {
          toggleNav(true);
        }, 200);
      });
    }

    function bindMenuItemsClick() {
      document.querySelectorAll('.nav-menu--list .menu-item a').forEach(function (item) {
        item.addEventListener('click', function () {
          toggleNav(true);
        });
      });
    }

    function toggleNav(hide) {
      if (hide || $nav.classList.contains(navActiveClass)) {
        $nav.classList.remove(navActiveClass);
        $burger.classList.remove(burgerActiveClass);
      } else {
        $nav.classList.add(navActiveClass);
        $burger.classList.add(burgerActiveClass);
      }
    }
  }

  function initAnchors() {
    $("a[href^='#']").on('click', anchorClickHandler)
    $("a[href^='/#']").on('click', function(e) {anchorClickHandler(e, true)})

    function anchorClickHandler(e, notPureAnchor) {
      let anchor;
      let anchorTarget;
      const href = $(e.target).attr('href') || '';

      if (!href) return;
      if (notPureAnchor && location.pathname !== href.split('#')[0]) return;

      anchor = e.target.href.match(/#[a-z]+$/);
      if (!anchor) return;

      anchorTarget = document.querySelector(anchor);
      if (!anchorTarget) return;

      const anchorPos = $(anchorTarget).offset().top;

      $('html, body').animate({'scrollTop': anchorPos}, 300)
      e.preventDefault();
    }
  }

  $(function () {
    initAnchors();
    initMobileNavToggle();
    initYoutubeLazy();
    customLazy();
  });
})(window);
