import ciStripe from "./stripe";
import {ciCreditKey} from "./creditKey";
import {ciPaypal} from "./paypal";

const ciPayment = {
  stripe: ciStripe,
  creditKey: ciCreditKey,
  paypal: ciPaypal,
  async validateCheckoutForm(licenseType) {
    const $form = $('#checkout');
    const formData = clickedin.payment.gatherFormData($form);
    let errorMessage = this.getValidationErrorMessage($form);

    if (errorMessage)
      return errorMessage;

    try {
      const resp = await verifyCheckoutData(licenseType, formData);

      if (!resp || !resp.status) {
        errorMessage = resp.error;
      }
    } catch (e) {
      errorMessage = e;
    }

    return errorMessage;
  },

  setErrorMessage(message) {
    const $error = $('p.error');
    $error.html(message);

    if (!!message) {
      $error.show();
    } else {
      $error.hide();
    }
  },

  getValidationErrorMessage($form) {
    let errorMessage;

    errorMessage = clickedin.general.encodingValidation($form)

    if ($("input.error").length > 0) {
      errorMessage = 'Please, correct form fields errors first.';
    }

    if ($('#terms').length && !$('#terms').is(':checked')) {
      errorMessage = 'Please, accept the terms and conditions.';
    }

    if ($("#agreeToReadWelcome").length && !$('#agreeToReadWelcome').is(':checked')) {
      errorMessage = 'Please, agree to read the welcome email.';
    }

    return errorMessage;
  },
  bindCheckoutSubmit(serverData) {
    let $form = $('#checkout');
    let $stripeButton = $('#pay-now-btn');
    let $creditKeyButton = $('#pay-ck-btn');
    const that = this;

    $form.on('submit.paymentSubmit', function (e) {
      e.preventDefault();
      that.setErrorMessage('');
      blockButtons();
      clickedin.general.fbTrack('AddPaymentInfo');
      const paymentProvider = $('.provider-btn.selected').data('provider') || 'stripe';

      that.validateCheckoutForm(serverData.licenseType)
        .then((errorMessage) => {
          if (errorMessage) {
            that.setErrorMessage(errorMessage);
            unblockButtons();
            return;
          }

          const providerMethod = paymentProvider === 'stripe' ? 'stripe' : 'creditKey';

          clickedin.payment[providerMethod].pay({...serverData}).then(({successUrl}) => {
            clickedin.general.fbTrack('Purchase')
            location.href = successUrl;
          }, (errorMessage) => {
            console.log('data verification error', errorMessage);
            that.setErrorMessage(errorMessage);
            unblockButtons();
          });
        });
    })

    function unblockButtons() {
      $stripeButton.text($stripeButton.data('text')).removeAttr("disabled").removeClass('hold');
      $creditKeyButton.attr('disabled', false).removeClass('hold');
    }

    function blockButtons() {
      $stripeButton.text('Verification...').attr("disabled", true).addClass('hold');

      if (!location.host.match(/^localhost/))
        $creditKeyButton.attr('disabled', true).addClass('hold');
    }

    return () => {
      $form.off('submit.paymentSubmit');
    }
  },
  gatherFormData($form) {
    let unindexedData = $form.serializeArray();
    let formData = {};

    $.map(unindexedData, function (n, i) {
      formData[n['name']] = n['value'];
    });

    return formData;
  }
}

const verifyCheckoutData = (licenseType, formData) => {
  return new Promise(function (resolve, reject) {
    if (licenseType === 'single') {
      resolve({status: true});
      return;
    }

    if (licenseType === 'team') {
      $.ajax({
        type: 'POST',
        url: clickedin.serverData.paymentBaseUrl + '/rest/verifyemail',
        data: formData,
        dataType: 'json',
        success: function (response) {
          resolve(response)
        },
        error: function (xhr, status, error) {
          reject(error.message)
        }
      });

      return;
    }
  })
}


export default ciPayment;
