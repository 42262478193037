import ciGeneral from "./general";
import ciPricingplan from "./pricingplan";
import ciPayment from "./payment/payment";
import ciManageUpgrades from "./payment/manageUpgrades";
import ciABTesting from "./abTesting";

let clickedin = {
  payment: ciPayment,
  pricingplan: ciPricingplan,
  general: ciGeneral,
  manageUpgrades: ciManageUpgrades,
  abTesting: ciABTesting,
  serverData: {
    paymentBaseUrl: null,
    stripePublicKey: null
  }
}

window.initMap = function () {
  new google.maps.places.SearchBox(document.getElementById('shippingAddress'));

  return false;
}

window.clickedin = clickedin;
