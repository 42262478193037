const ciStripe = {
  persistent: {
    stripeCard: null,
    stripeInstance: null
  },
  initStripe() {
    // Stripe Elements JS checkout here
    this.persistent.stripeInstance = Stripe(clickedin.serverData.stripePublicKey);

    let elements = this.persistent.stripeInstance.elements();
    this.persistent.stripeCard = elements.create('card', {
      style: {
        base: {
          color: '#1f1a47',
          fontSize: '16px',
          '::placeholder': {color: '#697386'}
        }
      }, hidePostalCode: true
    });

    this.persistent.stripeCard.mount('#card'); // card input field created
  },
  pay({licenseType}) {
    let $form = $('#checkout');
    const stripeSubscribeURL = licenseType === 'team' ? '/big-team-pack-subscribe' : '/ps-stripe-subscribe';

    return new Promise((resolve, reject) => {
      this.persistent.stripeInstance.createToken(this.persistent.stripeCard).then(function (tokenResult) {
        if (!tokenResult.token) {
          reject(tokenResult.error.message);
          return;
        }

        clearError();
        let formData = clickedin.payment.gatherFormData($form);
        formData['token'] = tokenResult.token.id;

        $.ajax({
          type: 'POST',
          url: stripeSubscribeURL,
          data: 'data=' + encodeURIComponent(JSON.stringify(formData)),
          dataType: 'json',
          success: function (response) {
            clickedin.pricingplan.setClientEmail(formData['email']);

            if (response && response.status) {
              if (formData['selected_package_id'] === 'webinar_special_gold_tei' ||
                  formData['selected_package_id'] === 'webinar_special_platinum_tei') {
                $(window).trigger('showUpsellModal');
              } else {
                const successUrl = `/thank-you-sub?em=${encodeURIComponent(formData['email'])}`;
                resolve({successUrl})
              }
            } else {
              try{window['grecaptcha'].reset()}catch (e) {}
              reject(response?.error);
            }
          },
          error: function (xhr, status, error) {
            reject(xhr.responseText);
          }
        });
      });
    })

    function clearError() {
      $('p.error').html('');
    }
  }
}

export default ciStripe;
