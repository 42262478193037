import $ from 'jquery';
import {WOW} from 'wowjs';
import 'jquery-lazy';
import Swiper from 'swiper/bundle';

(function () {
  function initSlider() {
    let $container = $('.testimonials-slider');

    if (!$container.length) return;

    new Swiper('.testimonials-slider', {
      direction: 'horizontal',
      loop: true,
      lazy: true,
      autoplay: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      grabCursor: false,
      slidesPerView: 1,
      slidesPerGroup: 1,
    })

    new Swiper('.g2-testimonials', {
      direction: 'horizontal',
      loop: true,
      lazy: true,
      autoplay: {
        delay: 4500,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      grabCursor: false,
    })
  }

  function initFaq() {
    const openedClass = 'faq--opened';

    $('.faq-wrap').on('click', '.question', function() {
      const $item = $(this).parent();

      if ($item.hasClass(openedClass)) {
        $item.removeClass(openedClass);
      } else {
        $item.addClass(openedClass);
      }
    });
  }

  function initForm() {
    $('.section--subscribe').on('click', '.btn', function() {
      const $form = $(this).parents('.section--subscribe');
      let error = '';

      showError('', $form);

      const company = $form.find('[name="subscribeCompany1"]').val();
      const name = $form.find('[name="subscribeName1"]').val();
      const email = $form.find('[name="subscribeEmail1"]').val();
      const phone = $form.find('[name="subscribePhone1"]').val();

      if (!company || !name || !email || !phone) {
        error = 'All fields are required';
      } else if (!email.match(/[a-zA-Z-._+]+@[a-zA-Z-._+0-9]+\.[a-zA-Z]{2,10}/)) {
        error = 'Please input a valid email';
      }

      if (error) {
        showError(error, $form);
        return;
      }

      disableAllForms();
      sendData({company, name, email, phone}).then(function(resp) {
        showSuccessOnAllForms();
      }).catch((resp) => {
        showSuccessOnAllForms();
      });
    });

    function showError(error, $form) {
      $form.find('.error').text(error);
    }

    function disableAllForms() {
      $('.section--subscribe .form').each(function() {
        const $form = $(this);
        const $btn = $form.find('.btn');
        $btn.prop('disabled', true).data('originalText', $btn.text()).text('Sending...');
      })
    }

    function sendData(data) {
      const fd = new FormData();

      fd.append('FNAME', data.name);
      fd.append('EMAIL', data.email);
      fd.append('BNAME', data.company);
      fd.append('PHONE', data.phone);

      const requestOptions = {
        method: 'POST',
        body: fd,
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache",
      };

       return fetch("https://leadgen.clickedin.net/site/subscribe", requestOptions)
        .then(response => response.text())
    }

    function showSuccessOnAllForms() {
      $('.section--subscribe .form').addClass('hidden');
      $('.section--subscribe .success').removeClass('hidden');
    }
  }

  function initAccordion() {
    const $toggle = document.getElementById('accordionToggle');
    if (!$toggle) return;
    const $title = $toggle.querySelector('.tab-title');
    const $content = $toggle.querySelector('.tab-content');

    $toggle.addEventListener('click', function (e) {
      e.preventDefault();

      if ($title.classList.contains('active')) {
        $title.classList.remove('active');
        slide($content, 0);
      } else {
        $title.classList.add('active');
        slide($content);
      }
    });

    function slide(el, endMaxHeight = 1000) {
      let progress;

      _slide();

      function _slide() {
        if (endMaxHeight === 0) {
          progress = 20;
          _slideUp();
        } else {
          progress = 1;
          _slideDown();
        }
      }

      function _slideDown() {
        el.style.maxHeight = (endMaxHeight * (progress / 20)).toString() + 'px';
        progress += 1;

        if (progress < 20)
          requestAnimationFrame(_slideDown);
      }

      function _slideUp() {
        el.style.maxHeight = (1000 * (progress / 20)).toString() + 'px';
        progress -= 1;

        if (progress > -1)
          requestAnimationFrame(_slideUp);
      }
    }
  }

  function initWow() {
    const wow = new WOW({
      animateClass: 'animate__animated',
      live: false,
      mobile: false
    });

    wow.init();
  }

  function initLazyLoading() {
    $("img.lazy").Lazy();
  }

  function initFooterBannerClose() {
    $(".footer-banner .close").on('click', () => {
      $('.footer-banner').fadeOut(200);
    });
  }

  function initMobileNav() {
    function burgerMenu(selector) {
      let menu = $(selector);
      let button = menu.find('.burger-menu_button', '.burger-menu_lines');
      let links = menu.find('.burger-menu_link');
      let overlay = menu.find('.burger-menu_overlay');

      button.on('click', (e) => {
        e.preventDefault();
        toggleMenu();
      });

      links.on('click', () => toggleMenu());
      overlay.on('click', () => toggleMenu());

      function toggleMenu(){
        menu.toggleClass('burger-menu_active');

        if (menu.hasClass('burger-menu_active')) {
          $('body').css('overlow', 'hidden');
        } else {
          $('body').css('overlow', 'visible');
        }
      }
    }

    burgerMenu('.burger-menu');
  }

  $(function () {
    if (!$('body').hasClass('home')) return;

    initAccordion();
    initSlider();
    initWow();
    initLazyLoading();
    initFooterBannerClose();

    initMobileNav();
    initFaq();
    initForm();
  });
})();
