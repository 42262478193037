export default class PricingPlanPage {
  constructor() {
    this.isInitDone = false;
    this.flushers = [];
    this.serverVariables = {};
  }

  init(data, isReInit) {
    if (this.isInitDone && !isReInit) return;
    this.serverVariables = data;

    if (this.isInitDone) {
      this.flush();
      clickedin.pricingplan.recalculatePriceDefault();
    } else {
      clickedin.general.fbTrack();
      clickedin.payment.stripe.initStripe();
      clickedin.pricingplan.initPaymentMethodSwitch();
    }

    this.isInitDone = true;

    this.flushers.push(
      clickedin.pricingplan.initUpsellModals(data.packageId)
    )

    this.flushers.push(
      clickedin.payment.paypal.initPaypal(data)
    )

    this.flushers.push(
      clickedin.pricingplan.initUserPromoCode(data.agent, data.packageId, data.licenseType)
    );
    this.flushers.push(
      clickedin.payment.bindCheckoutSubmit(data)
    );
    this.flushers.push(
      clickedin.pricingplan.preventDuplicateEmailEnterForTeam(data.licenseType)
    );
    this.flushers.push(
      clickedin.pricingplan.initTeamPlanUsers({
        licenseType: data.licenseType,
        teamSize: data.teamSize,
        hasTrial: !!data.trialDays
      })
    );
  }

  flush() {
    this.flushers.forEach((flusher) => {
      if (typeof flusher === 'function') {
        flusher();
      }
    });

    this.flushers = [];
  }
}
