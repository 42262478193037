import $ from 'jquery';
import PricingPlanPage from "./pricingplanPageControl";

let isPageInitDone = false;
let checkoutMainEmail = 'yuri+2384@clickedin.io';

const ciPricingplan = {
  setClientEmail(email) {
    checkoutMainEmail = email;
  },
  initTeamPlanUsers({licenseType, recalculatePriceFn, startIndex = 0, teamSize, hasTrial = false}) {
    if (licenseType !== 'team') return;
    const recalculatePrice = recalculatePriceFn || clickedin.pricingplan.recalculatePriceDefault;

    createExtraUsers(recalculatePrice, teamSize, startIndex);
    bindUserAddButton();
    bindUserDeleteButton();

    function bindUserAddButton() {
      $("#add-user-btn").on('click.teamUsersActions', function (e) {
        e.preventDefault();

        const startIndex = $('.secondary-user-row').length;
        addSecondaryUserRow(startIndex, recalculatePrice, true);
        return false;
      });
    }

    function bindUserDeleteButton() {
      $(".users-container").on('click.teamUsersActions', ".delete-user-btn", function (e) {
        e.preventDefault();

        let this_user_cont = $(this).closest(".secondary-user-row");
        this_user_cont.fadeOut(200, function () {
          $(this).remove();
          recalculatePrice()
        });

        return false;
      });
    }

    function addSecondaryUserRow(nonAdminRowIndexStartingFromZero, recalculatePrice, withDelete = false) {
      let container = $(".users-container");
      let userIndex = nonAdminRowIndexStartingFromZero + 2; // current field counter

      let secondaryRow = $("<div/>").addClass("single-form secondary-user-row");

      let rowObj = $("<div/>").addClass("row").appendTo(secondaryRow);

      let emailGroup = $("<div/>").addClass("form-group column col-33 col-100-sm").appendTo(rowObj);
      $("<label/>").attr('for', 'teamUserEmail-' + userIndex).html('Business Email').appendTo(emailGroup);
      $("<input/>")
        .attr('type', 'email')
        .attr('name', 'teamUserEmail-' + userIndex)
        .attr('id', 'teamUserEmail-' + userIndex)
        .addClass('email-input')
        .appendTo(emailGroup);

      let firstNameGroup = $("<div/>").addClass("form-group column col-33 col-100-sm").appendTo(rowObj);
      $("<label/>").attr('for', 'teamUserFirstName-' + userIndex).html('First Name').appendTo(firstNameGroup);
      $("<input/>")
        .attr('type', 'text')
        .attr('name', 'teamUserFirstName-' + userIndex)
        .attr('id', 'teamUserFirstName-' + userIndex)
        .addClass('email-input')
        .appendTo(firstNameGroup);

      let lastNameGroup = $("<div/>").addClass("form-group column col-33 col-100-sm").appendTo(rowObj);
      $("<label/>").attr('for', 'teamUserLastName-' + userIndex).html('Last Name').appendTo(lastNameGroup);
      $("<input/>")
        .attr('type', 'text')
        .attr('name', 'teamUserLastName-' + userIndex)
        .attr('id', 'teamUserLastName-' + userIndex)
        .addClass('email-input')
        .appendTo(lastNameGroup);

      if (withDelete) {
        $("<button/>").addClass('delete-user-btn')
          .html('x')
          .attr('value', 'x')
          .attr('title', 'Delete User')
          .appendTo(rowObj);
      }

      secondaryRow.appendTo(container);

      recalculatePrice();

      return true;
    }

    function createExtraUsers(recalculatePrice, teamSizeOrAddAmount, startIndex){
      if (startIndex === 0) {
        // teamSizeOrAddAmount treated as team size, -1 to substract admin user which is always added
        for (let i = 0; i < teamSizeOrAddAmount - 1; i++) {
          addSecondaryUserRow(i, recalculatePrice);
        }
      } else {
        for (let i = 0; i < teamSizeOrAddAmount; i++) {
          addSecondaryUserRow(startIndex + i, recalculatePrice);
        }

      }
      toggleAddUserButton();
    }

    function toggleAddUserButton(){
      const maxUsersAmount = 99;

      if (($(".secondary-user-row").length + 1) >= maxUsersAmount) {
        $("#add-user-btn").hide();
      } else {
        $("#add-user-btn").show();
      }
    }

    return () => {
      // recalculatePrice();
      $("#add-user-btn").off('.teamUsersActions');
      $(".users-container").off('.teamUsersActions');
    }
  },
  initPaymentMethodSwitch() {
    if (!$('.payment-methods').length)
      return;

    let cont = $('.payment-methods');

    $(".payment-btn", cont).on('click', function () {
      $(".selected", cont).removeClass('selected');

      $(this).closest(".btn-column").addClass('selected');
      const provider = $(this).parent().data('provider');
      $('.payment-method-form').hide();
      $(`.payment-method-form[data-provider=${provider}]`).show();

      return false;
    });
  },

  recalculatePriceDefault() {
    const secondaryUsersCount = parseInt($(".secondary-user-row").length);
    $("#secondaryUsersCount").val(secondaryUsersCount);

    const mainPrice = parseFloat($("#mainPrice").val()) || 0;
    const teamSize = parseFloat($("#teamSize").val())  || 0;
    const setupFee = parseFloat($("#setupFee").val()) || 0;
    const additionalPrice = parseFloat($("#additionalPrice").val()) || 0;
    const trialDays = parseFloat($("#trialDays").val()) || 0;

    const upgradesPrice = !trialDays ? parseFloat($('#upgradesSection').data('upgradesPrice') || 0) : 0.00;
    const additionalUsersPrice = !trialDays ? additionalPrice * ((secondaryUsersCount + 1) - teamSize): 0.00;
    const recurringPrice = mainPrice + upgradesPrice + additionalUsersPrice;
    const totalPrice = !trialDays ? parseFloat(recurringPrice + setupFee).toFixed(2): 0.00;
    const firstRecurringCharge = parseFloat(recurringPrice).toFixed(2);

    $("#totalPrice").val(totalPrice);
    $('#upgradesTotalPrice').text(`$${upgradesPrice.toFixed(2)} USD`);
    $("#todayTotalPrice").html(`$${totalPrice} USD`);
    $("#firstRecurringCharge").html('$' + firstRecurringCharge.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    }) + ' USD');
  },
  preventDuplicateEmailEnterForTeam(licenseType) {
    if (licenseType !== 'team') return;

    $('body').on('input.duplicateEmails', 'input[type=email]', function() {
      let emails = [];

      $('input[type=email]').each(function() {
        const val = $(this).val().trim().toLowerCase();

        if (val && val.match('@')) {
          emails.push(val)
        }
      })

      const duplicates = findDuplicates(emails);

      if (duplicates.length) {
        blockFormAndShowError();
      } else {
        unblockButtonsAndHideError();
      }
    });

    function unblockButtonsAndHideError() {
      $('button[type=submit]').each(function() {
        if ($(this).hasClass('duplicate-emails-error')) {
          $(this).attr("disabled", false).removeClass('hold').removeClass('duplicate-emails-error');
        }
      })

      const $errorBlock = $('p.error');

      if ($errorBlock.hasClass('duplicate-emails-error')) {
        $errorBlock.html('').removeClass('duplicate-emails-error');
      }
    }

    function blockFormAndShowError(){
      $('button[type=submit]').attr("disabled", true).addClass('hold').addClass('duplicate-emails-error');
      $('p.error').addClass('duplicate-emails-error').html('Please make sure that the emails of all team members are unique.');
    }

    function findDuplicates(array, count = {}) {
      array.forEach(el => count[el] ? count[el]++ : count[el] = 1);
      return Object.keys(count).filter(key => count[key] > 1);
    }

    return () => {
      unblockButtonsAndHideError();
      $('body').off('.duplicateEmails');
    }
  },

  initUpgrades() {
    const $section = $('#upgradesSection');
    const $checkboxes = $('.pricingplan-upgrades-list :checkbox');

    if (!$section.length) return;

    $checkboxes.on('change', function() {
      updatePriceDataAttr($(this));
      updateHiddenInput();
      clickedin.pricingplan.recalculatePriceDefault();
    }).trigger('change');

    function updateHiddenInput() {
      const upgrades = [];

      $checkboxes.filter(':checked').each(function() {
        upgrades.push($(this).data('upgrade'));
      });

      $('#upgrades').val(upgrades.join(','))
    }

    function updatePriceDataAttr($cb) {
      let total = 0;
      const hasSale = $('#upgradesSale').val() !== '0';

      if (hasSale) {
        const [nonFreeThreshold, freeAmount] = $('#upgradesSale').val().split('+').map(item => parseInt(item));
        markFree($cb, nonFreeThreshold, freeAmount);
      }

      $checkboxes.filter(':not(.free):checked').each(calculatePrice);

      function markFree($cb, nonFreeThreshold, freeMaxAmount){
        if (!$cb.length) return;

        const selected = $checkboxes.filter(':checked').length;
        const selectedFree = $checkboxes.filter(':checked').filter('.free').length;

        if (!$cb.is(':checked')) {
          $cb.removeClass('free');

          const selectedNonFree = selected - selectedFree;

          if (selected <= nonFreeThreshold) {
            $checkboxes.filter('.free').removeClass('free');
          } else if (selectedNonFree < nonFreeThreshold) {
            $checkboxes.filter(':checked.free').slice(0, nonFreeThreshold - selectedNonFree).removeClass('free');
          } else if (selectedNonFree > nonFreeThreshold && selectedFree < freeMaxAmount) {
            const freeSlotsLeft = Math.max(freeMaxAmount - selectedFree, 0);
            const vacantForFree = selectedNonFree - nonFreeThreshold;

            $checkboxes.filter(':checked:not(.free)')
              .slice(0, Math.min(freeSlotsLeft, vacantForFree))
              .addClass('free');
          }
        } else {
          if (selected > nonFreeThreshold && selectedFree < freeMaxAmount) {
            $cb.addClass('free');
            // $('#freeUpgrades').show().find('.amount').text(selectedFree + 1);
          }
        }
      }

      function calculatePrice(){
        total += parseFloat($(this).data('price'));
      }

      $section.data('upgradesPrice', total);
    }
  },
  validateToken(token) {
    if (!token) return;
    let overlayObj = $("<div/>").addClass("pricing-overlay");
    let notice = $("<h2/>").html('Checkout validation. Please, wait...').appendTo(overlayObj);

    overlayObj.appendTo('body');

    $.ajax({
      type: 'POST',
      url: 'https://clickedin.net/api/validate-token',
      data: {token: token},
      headers: {Authorization: 'd3F3d3Fxd3F3Ond3d3F3d3F3cXdx'},
      dataType: 'json',
      timeout: 5000,
      success: function (response) {
        if (response && response.status) {
          $('.pricing-overlay h2').html('Сheckout allowed');
          $('.pricing-overlay').fadeOut('400', function () {
            $(this).remove();
          });
        } else {
          $('.pricing-overlay h2').html('Сheckout validation error. Reload page and try again, please.');
        }
      },
      error: function (xhr, status, error) {
        if (status === "timeout") {
          $('.pricing-overlay h2').html('Timeout error. Reload page and try again, please.');
        } else if (xhr.responseJSON.status == false && xhr.responseJSON.type == 'used') {
          $('.pricing-overlay h2').html('Sorry, this checkout was already used.');
        } else if (xhr.responseJSON.status == false && xhr.responseJSON.type == 'expired') {
          $('.pricing-overlay h2').html('Sorry, this checkout expired.');
        } else {
          $('.pricing-overlay h2').html('Checkout validation error. Reload page and try again, please.');
        }
      }
    });
  },

  initUserPromoCode(agent, packageId, licenseType) {
    const $applyBtn = $('#promoUserCodeSection .apply');
    const $activateBtn = $('#promoUserCodeSection .activate');
    const $codeInput = $('#userPromoCode');
    const $error = $('#promoUserCodeSection .input-error');

    $activateBtn.on('click.promoCode', function(e) {
      e.preventDefault();

      $(this).parent().addClass('hidden');
      $('.pricingplan-user-promocode-edit').removeClass('hidden');
    });

    $codeInput.on('input.promoCode', function(e) {
      $applyBtn.attr('disabled', !getCode());
    })

    $applyBtn.on('click.promoCode', function(e) {
      e.preventDefault();
      const code = getCode();
      if (!code) return;

      $applyBtn.prop('disabled', true);
      $error.removeClass('active').text('');

      $.ajax(
        {
          url: `/apply-code?code=${code}&packageId=${packageId}&agent=${agent ? agent : ''}`,
          dataType: 'json',
          method: 'GET'
        }
      ).then(function(response) {
        if (!response) return;

        if(response.error) {
          $error.addClass('active').text(`${response.error}`);
          return;
        }

        applyPackage(response, code, packageId);
        applyCode(code);

      }, function() {
        $error.addClass('active').text(`Something went wrong`);
        $applyBtn.prop('disabled', false);
      })
    });

    function getCode(){
      return $codeInput.val().replace(/[^a-zA-Z0-9-_]/gm, '').trim();
    }

    function applyCode(code){
      $('.pricingplan-user-promocode-activate').addClass('hidden');
      $('.pricingplan-user-promocode-edit').addClass('hidden');
      $('.pricingplan-user-promocode-active').removeClass('hidden');
      $('.pricingplan-user-promocode-active .code').text(code);
      $('#promoCode').val(code);
    }

    function applyPackage(response, code, packageId){
      const $html = $(response.html);
      if (response.variables.licenseType !== licenseType) {
        $('#uncommonFieldsWrap').html($html.find('#uncommonFieldsWrap'));

        if (licenseType === 'team') {
          // switching from team to single
        } else {
          // switching from single to team
        }
      }
      $('#orderDetailsSection').html($html.find('#orderDetailsSection'));
      $('#extraFormData').html($html.find('#extraFormData'));
      $('#teamUsers').html($html.find('#teamUsers'));
      window.history.replaceState({}, 'Promo', `${location.protocol}//${location.host}/pricingplan/${response.variables.packageId}/${code}`);
      clickedin.pricingplan.pageControl.init(response.variables, true);
    }

    return () => {
      $activateBtn.off('.promoCode');
      $applyBtn.off('.promoCode');
      $codeInput.off('.promoCode');
    }
  },

  initUpsellModals(packageId) {
    const $fader = $('#modalFader');
    if (!$fader.length) return;

    let modalPackages = {};

    if (packageId === 'webinar_special_gold_tei') {
      modalPackages = {
        'add2': 'webinar_special_gold_teii',
        'add2long': 'webinar_special_gold_teiy',
        'soloLong': 'webinar_special_gold_tef'
      };
    } else if (packageId === 'webinar_special_platinum_tei') {
      modalPackages = {
        'add2': 'webinar_special_platinum_teii',
        'add2long': 'webinar_special_platinum_teiy',
        'soloLong': 'webinar_special_platinum_tef'
      };
    } else {
      return;
    }

    const modals = {
      add2: {
        $el: $('#modalAdd2'),
        init() {
          this.$el.on('click', '.modal-actions button', function() {
            if ($(this).hasClass('accept')) {
              showModal('add2form');
            } else {
              showModal('soloPlusLength');
            }
          })
        }
      },
      add2form: {
        $el: $('#modalAdd2form'),
        init() {
          this.$el.on('click', '.modal-actions button', function() {
            if ($(this).hasClass('accept')) {
              const $form = $('#modalTeamMembersForm');
              const $modal = $(this).parents('.modal');

              const formData = clickedin.payment.gatherFormData($form);
              hideSubmitError($modal);

              if (!checkoutMainEmail || !$form[0].reportValidity()) return;
              blockButton($(this));

              upgradeSubscription({
                members: formData,
                customerEmail: checkoutMainEmail,
                newPackage: modalPackages['add2']
              }, {$modal, $button: $(this), successModal: 'add2PlusLength'});
            } else {
              showModal('soloPlusLength');
            }
          })
        }
      },
      add2PlusLength: {
        $el: $('#modalAdd2PlusLength'),
        init() {
          this.$el.on('click', '.modal-actions button', function() {
            if ($(this).hasClass('accept')) {
              blockButton($(this));
              const $modal = $(this).parents('.modal');
              hideSubmitError($modal);
              upgradeSubscription({
                customerEmail: checkoutMainEmail,
                newPackage: modalPackages['add2long']
              }, {$modal, $button: $(this), successModal: 'thankYou'});
            } else {
              showModal('thankYou');
            }
          })
        }
      },
      soloPlusLength: {
        $el: $('#modal1PlusLength'),
        init() {
          this.$el.on('click', '.modal-actions button', function() {
            if ($(this).hasClass('accept')) {
              blockButton($(this));
              const $modal = $(this).parents('.modal');
              hideSubmitError($modal);
              upgradeSubscription({
                customerEmail: checkoutMainEmail,
                newPackage: modalPackages['soloLong']
              }, {$modal, $button: $(this), successModal: 'thankYou'});
            } else {
              showModal('thankYou');
            }
          })
        }
      },
      thankYou: {
        $el: $('#modalThankYou'),
        init() {
          this.$el.on('click', '', function () {
            location.href = `/thank-you-sub?em=${encodeURIComponent(checkoutMainEmail)}`
          });
        }
      }
    }

    function blockButton($button) {
      $button.prop('disabled', true).data('originalText', $button.text()).text('Processing...')
    }

    function unBlockButton($button) {
      $button.prop('disabled', true).text($button.data('originalText'))
    }

    function showSubmitError($modal, message) {
      $modal.find('.error').show().text(message);
    }

    function hideSubmitError($modal) {
      showSubmitError($modal, '');
    }

    function upgradeSubscription(data, {successModal, $modal, $button}) {
      return $.ajax({
        type: 'POST',
        url: '/upgrade-subscription',
        data: 'data=' + encodeURIComponent(JSON.stringify(data)),
        dataType: 'json'
      }).done(function(resp) {
        if (resp?.status !== true) {
          const error = resp?.error || 'Something went wrong.';
          showSubmitError($modal, error);
          unBlockButton($button)
        } else {
          showModal(successModal);
        }
      }).fail((err) => {
        const error = err?.message || 'Invalid server response.';
        showSubmitError($modal, error);
      })
    }

    function init() {
      // showModal('add2form');
      Object.values(modals).forEach(modal => {
        modal?.init();
      });
    }

    init();

    $(window).on('showUpsellModal', function() {
      showModal('add2');
    });

    function showModal(name) {
      hideModals();
      $fader.show();
      modals[name].$el.fadeIn(250);
    }

    function hideModals() {
      $fader.hide();
      Object.values(modals).forEach((modal, b) => {
        modal.$el.stop(true, true).hide();
      })
    }

    return () => {
      // todo write unbinder
    }
  },

  flushPage() {

  },
  pageControl: new PricingPlanPage()
}

export default ciPricingplan;
