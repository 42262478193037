import $ from 'jquery';

const ciGeneral = {
  encodingValidation(form) {
    let under_validation = $(".under-validation", form);
    let errorMessage;

    let fields = $(":input", under_validation);
    for (let i = 0; i < fields.length; i++) {
      let input = $(fields[i]);
      let pattern = /^[\x20-\x7E]+$/;
      let input_value = input.val();

      if (input.hasClass('no-validation')) continue; // skip fields which doesn't need validation

      if (input_value && !input_value.match(pattern)) {
        return 'Sorry, we do not accept non EN characters in form fields. Correct it and try again, please.';
      }
    }

    return errorMessage;
  },
  allowOnlyBusinessEmails($container) {
    return true;
    $container.on('keyup', 'input[type=email]', function () {
      let email = $(this).val().toLowerCase();

      let st = false;

      if (email.endsWith('@hotmail.com') ||
        email.endsWith('@gmail.com') ||
        email.endsWith('@outlook.com') ||
        email.endsWith('@yahoo.com') ||
        email.endsWith('@aol.com') ||
        email.endsWith('@mail.com') ||
        email.endsWith('@rambler.ru') ||
        email.endsWith('@example.com') ||
        email.endsWith('@foxybox.ca') ||
        email.endsWith('@live.com')) {
        st = true;
      }

      if (st) {
        $(this).addClass('error');
        $(this).parent().find('label mark').remove();
        $(this).parent().find('label').append('<mark> Please use business email!</mark>');
      } else {
        $(this).removeClass('error');
        $(this).parent().find('label mark').remove();
        $('p.error').html('');
      }
    });
  },
  fbTrack(event = 'InitiateCheckout') {
    if (!!window['fbq']) {
      fbq('track', event);
    }
  },
}

export default ciGeneral
